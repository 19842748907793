<template>
    <mercur-card class="mb-4">
        <h2 class="mt-1 font-weight-normal">Private notes</h2>
        <template v-if="hasPermission('MerchantCentral/listNotesForAccount')">
            <mercur-spinner v-if="isFetchingResources"></mercur-spinner>
            <template v-if="hasPermission('MerchantCentarl/listNotesForAccount')">
                <ul class="note">
                    <li class="mb-2" v-for="(note, index) in items" :key="index">
                        <div class="d-flex justify-content-between">
                            <div class="p-2">{{ note.content }}</div>
                            <mercur-button
                                class="btn btn-icon btn-icon-sm p-2" v-if="hasPermission('removeNoteForAccount')"
                                @click.native="deleteNote(note.noteId)"
                                :disabled="loading">
                                <i class="fas fa-trash"></i>
                            </mercur-button>
                        </div>
                        <div class="px-2 pb-2">
                            <div>
                                <small>by: <span v-text="getUserInfo(note.createdBy)" /></small>
                            </div>
                            <small>at: {{note.dateCreated}}</small>
                        </div>
                    </li>
                </ul>
            </template>
            <span v-else>
                Not allowed to see this view
            </span>
        </template>
        <form @submit.prevent="submitForm" v-if="hasPermission('MerchantCentral/addNoteForAccount')">
            <mercur-textarea v-model="form.content" :class="getValidationClass($v, 'content')">
                Note content
                <template #note>
                    <span class="form-error" v-if="!$v.form.content.required">Content is required</span>
                </template>
            </mercur-textarea>
            <div class="text-right">
                <mercur-button class="btn btn-primary" type="submit">Add note</mercur-button>
            </div>
        </form>
    </mercur-card>
</template>

<script>
import ResourcesView from '@/views/ResourcesView'
import CONFIG from '@root/config'
import FormMixin from '@/mixins/Form'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'
export default {
    name: 'CustomerPrivateNotes',
    mixins: [ ResourcesView, FormMixin ],
    data () {
        return {
            url: CONFIG.API.ROUTES.CUSTOMERS.NOTES.OVERVIEW.replace('{customerId}', this.$route.params.customerId),
            form: {
                topic: 'general',
            },
        }
    },
    validations: {
        form: {
            content: {
                required,
            },
        },
    },
    computed: {
        ...mapState('agents', ['agents']),
        action () {
            return CONFIG.API.ROUTES.CUSTOMERS.NOTES.ADD.replace('{customerId}', this.$route.params.customerId).replace('{agentId}', this.$store.state.auth.user.accountId)
        },
    },
    methods: {
        ...mapActions('agents', ['fetchAllAgents']),
        getUserInfo (userId) {
            const agent = this.agents.find(agent => (agent.accountId === userId))
            return agent.firstName + ' ' + agent.lastName
        },
        submitForm () {
            this.submit(this.action, this.form).then((data) => {
                this.items.push(data.data)
                this.form = {
                    topic: 'general',
                }
                this.$root.$emit('notification:global', {
                    message: 'Customer note was added',
                })
            })
        },
        deleteNote (id) {
            const url = CONFIG.API.ROUTES.CUSTOMERS.NOTES.REMOVE.replace('{customerId}', this.$route.params.customerId).replace('{noteId}', id)

            this.addJob(url)
            this.loading = true

            this.$api.post(url, {}).then(({ data }) => {
                const index = this.items.findIndex(item => item.noteId === data.data.noteId)
                this.items.splice(index, 1)
                this.$root.$emit('notification:global', {
                    message: 'Customer note was removed',
                })
            }).catch(err => {
                this.$root.$emit('notification:global', {
                    message: err.response,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(url)
            })
        },
    },
    created () {
        this.fetchAllAgents()
    },
}
</script>
<style lang="scss" scoped>
.note {
    list-style: none;
    padding-left: 0;
    li {
        background-color: #f1f1f1;
    }
}
</style>
