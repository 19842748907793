<template>
    <div v-if="Object.keys(customer).length" class="c-customer pb-5">
        <v-title :title="`${customer.firstName} ${customer.lastName} - Customers`"></v-title>
        <title-box>
            <div class="row">
                <div class="col-6">
                    <h1>{{ customer.firstName }} {{ customer.lastName }} <span v-if="customer.dateRevoked" class="c-customer__revoked">(REVOKED ACCOUNT)</span></h1>
                </div>
                <div class="col-6 text-right">
                    <mercur-button class="btn" @click.native="impersonate" :disabled="impersonating || !selectedShop.configuration.shopinfo">Login as {{ customer.firstName }} {{ customer.lastName }}</mercur-button>
                    <mercur-button class="btn" v-if="hasPermission('requestPasswordReset')" @click.native="requestResetPassword" :disabled="isResetting || customer.accountType === 'ANONYMOUS'">Reset password</mercur-button>
                    <mercur-button class="btn" v-if="!customer.dateRevoked" @click.native="revokeCustomer" :disabled="isLoading || customer.accountType === 'ANONYMOUS'">Revoke access</mercur-button>
                    <mercur-button class="btn" v-if="customer.dateRevoked" @click.native="reactivateCustomer" :disabled="isLoading">Reactivate customer</mercur-button>
                </div>
            </div>
        </title-box>
        <div class="container-fluid">
            <div class="row mt-n4" v-if="customer">
                <div class="col-8">
                    <customer-general-information></customer-general-information>
                </div>
                <div class="col-4">
                    <customer-private-notes></customer-private-notes>
                </div>
            </div>
            <!--ACCOUNT CONFIGURATIONS-->
            <div class="row">
                <div class="col-12">
                    <mercur-card class="mb-5">
                        <h2 class="mt-1 font-weight-normal">
                            Account Configurations
                            <mercur-button class="btn btn-icon" @click.native="isEditingAccountConfigurations = true">
                                <i class="fas fa-edit"></i>
                            </mercur-button>
                        </h2>
                        <div class="row mb-3" v-if="!isEditingAccountConfigurations">
                            <div class="col-3" v-for="(configuration, configurationKey) in accountConfigurationSchemas.properties" :key="configurationKey">
                                <fieldset class="c-customer__account-configuration-entry">
                                    <legend  class="c-customer__account-configuration-entry-title"><strong>{{configurationKey}}</strong></legend>
                                    <div v-for="(field, fieldKey) in configuration.properties" :key="fieldKey">
                                        <b>{{fieldKey}}: </b>
                                        <span>{{getAccountConfigurationFieldValue(configurationKey, fieldKey)}}</span>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div v-else>
                            <account-configuration-parser
                                v-if="accountConfigurationSchemas"
                                :schema="accountConfigurationSchemas"
                                @input="handleConfigurationInput"
                                :value="customer.accountConfiguration">
                            </account-configuration-parser>
                            <mercur-button class="btn btn-primary mt-3" @click.native="saveAccountConfiguration">Save</mercur-button>
                        </div>
                    </mercur-card>
                </div>
            </div>
        </div>

        <div class="container-fluid">
            <div class="row" v-if="customer.organisation.organisationId">
                <div class="col-12">
                    <h1 class="mt-1 font-weight-normal">
                        Addresses
                        <mercur-button data-e2e="customerAddressesAddButton" class="btn btn-icon btn-yellow text-white" @click.native="addAddress" :disabled="customer.accountType === 'ANONYMOUS'">
                            <i class="fas fa-plus"></i>
                        </mercur-button>
                    </h1>
                    <customer-addresses class="mb-5" @editAddress="editAddress" @setBilling="setDefaultBillingAddress" @setShipping="setDefaultShippingAddress" :canSetDefault="true" :customerId="customer.accountId" :organisationId="customer.organisation.organisationId" ref="customerAddresses"></customer-addresses>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-12">
                    <h1 class="mt-1 font-weight-normal">Invoices</h1>
                    <div>
                        <merchant-data-table style="height: 250px; min-height: unset;" class="shadow" :url="invoicesUrl" :options="optionsInvoices"></merchant-data-table>
                    </div>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-8">
                    <h1 class="mt-1 font-weight-normal">Orders</h1>
                </div>
                <div class="col-4 text-right">
                    <mercur-button class="btn btn-primary" @click.native="addOrderForCustomer">Add order</mercur-button>
                </div>
                <div class="col-12">
                    <mercur-card class="mb-4">
                        <table-search-filter @change="applyFilter" :filterFields="filterFields" :dropdownFilters="dropdownFilters"></table-search-filter>
                    </mercur-card>
                    <OrderTable :url="customerOrdersUrl" ref="table" :params="{ hideSecondaryPagination: true }" presetRowNumber="5"></OrderTable>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h1 class="mt-1 font-weight-normal">Quotes</h1>
                    <mercur-card class="mb-4">
                        <table-search-filter @change="applyFilterToQuotes" :filterFields="quoteFilterFields" :dropdownFilters="quoteDropdownFilters"></table-search-filter>
                    </mercur-card>
                    <QuotesTable :url="customerQuotesUrl" ref="tableQuotes" presetRowNumber="5"></QuotesTable>
                </div>
            </div>
        </div>

        <mercur-dialog :is-open.sync="isDialogOpen" @close="resetForm">
            <address-form ref="addressForm" :options="addressFormOptions"  @success="success"></address-form>
        </mercur-dialog>
        <form id="impersonateForm" target="_blank" :action="action" method="post">
            <input id="impersonateForm.accessToken" name="accessToken" type="hidden" />
        </form>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import FormMixin from '@/mixins/Form'
import OrderTable from '@/components/elements/order/Table'
import CustomerAddresses from '@/components/elements/customers/Addresses'
import MerchantDataTable from '@/components/elements/MerchantDataTable'
import AddressForm from '@/components/elements/AddressForm'
import { mapActions, mapState } from 'vuex'
import CustomerGeneralInformation from '@/components/elements/customers/GeneralInformation'
import CustomerPrivateNotes from '@/components/elements/customers/PrivateNotes'
import TableSearchFilter from '@/components/elements/table/SearchFilter'
import moment from 'moment'
import QuotesTable from '@/components/elements/quotes/Table'
import AccountConfigurationParser from '../components/elements/customers/AccountConfigurationParser'

export default {
    name: 'CustomerView',
    mixins: [FormMixin],
    components: { OrderTable, CustomerAddresses, MerchantDataTable, AccountConfigurationParser, CustomerGeneralInformation, AddressForm, TableSearchFilter, CustomerPrivateNotes, QuotesTable },
    data () {
        return {
            isDialogOpen: false,
            addressFormOptions: null,
            impersonating: false,
            isResetting: false,
            isLoading: false,
            filterFields: {
                'orderNumber': { 'label': 'OrderId' },
            },
            quoteFilterFields: {
                'cartId': { 'label': 'QuoteId' },
            },
            dropdownFilters: [
                {
                    'column': 'orderStatus',
                    'label': 'Order Status',
                    'operator': 'equals',
                    'values': {
                        'Open': 'OPEN',
                        'Cancelled': 'CANCELLED',
                        'Placed': 'PLACED',
                        'Awaiting Payment': 'AWAITING_PAYMENT',
                        'Paid': 'PAID',
                        'Refunded': 'REFUNDED',
                        'Validated': 'VALIDATED',
                    },
                },
                {
                    'column': 'dateCreated',
                    'label': 'Date Created',
                    'operator': 'greaterThan',
                    'values': {
                        'Today': this.getDateOffset(0),
                        '1 day ago': this.getDateOffset(1),
                        '3 days ago': this.getDateOffset(3),
                        '7 days ago': this.getDateOffset(7),
                        '1 month ago': this.getDateOffset(30),
                    },
                },
            ],
            quoteDropdownFilters: [
                {
                    'column': 'status',
                    'label': 'Quote Status',
                    'operator': 'equals',
                    'values': {
                        'Open': 'OPEN',
                        'Checked Out': 'CHECKED_OUT',
                        'Rejected': 'REJECTED',
                        'closed': 'CLOSED',
                        'finalized': 'FINALIZED',
                        'approved': 'APPROVED',
                    },
                },
                {
                    'column': 'dateCreated',
                    'label': 'Date Created',
                    'operator': 'greaterThan',
                    'values': {
                        'Today': this.getDateOffset(0),
                        '1 day ago': this.getDateOffset(1),
                        '3 days ago': this.getDateOffset(3),
                        '7 days ago': this.getDateOffset(7),
                        '1 month ago': this.getDateOffset(30),
                    },
                },
            ],
            optionsInvoices: {
                columns: {
                    'Invoice Number': { field: 'invoiceNumber', width: 220 },
                    'Order Id': {
                        field: 'orderNumber',
                        link: (value, data) => {
                            return {
                                name: 'OrderWrapper',
                                params: {
                                    applicationId: data.applicationId,
                                    accountId: data.accountId,
                                    orderId: data.orderId,

                                },
                            }
                        },
                    },
                    'Shop': { field: 'applicationName' },
                    'Invoice Date': { field: 'dateCreated' },
                    'Due Date': { field: 'dateCreated' },
                    'Payment Method': { field: 'paymentMethod' },
                    'Invoice Status': { field: 'status', statusChip: true },
                    'Total': { field: 'totals.total' },
                },
                actions: {
                    type: 'dropdown',
                    items: [
                        {
                            text: 'Download PDF',
                            onClick: ({ data }) => {
                                this.downloadInvoice(data)
                            },
                        },
                    ],
                },
                paginationPageSize: 10,
                cacheBlockSize: 10,
            },
            isEditingAccountConfigurations: false,
            accountConfigurationSchemas: {},
            isLoadingConfigurations: true,
            accountConfigurations: {},
        }
    },
    computed: {
        ...mapState('customer', ['customer']),
        breadCrumbId () {
            if (!Object.keys(this.customer).length) {
                return '...'
            }
            return `${this.customer.firstName} ${this.customer.lastName}`
        },
        invoicesUrl () {
            return CONFIG.API.ROUTES.INVOICES.ACCOUNT
                .replace('{accountId}', this.$route.params.customerId).replace('{agentId}', this.$store.state.auth.user.accountId)
        },
        customerOrdersUrl () {
            return CONFIG.API.ROUTES.ORDERS.GET_FOR_ACCOUNT.replace('{accountId}', this.$route.params.customerId)
        },
        customerQuotesUrl () {
            return CONFIG.API.ROUTES.CART.LIST_FOR_ACCOUNT.replace('{accountId}', this.$route.params.customerId)
        },
        action () {
            if (!this.selectedShop.configuration || !this.selectedShop.configuration.shopinfo) {
                return
            }
            return `https://${this.selectedShop.configuration.shopinfo.domain}/${this.customer.countryCode}/${this.customer.language}/impersonate`
        },
        updateAction () {
            return CONFIG.API.ROUTES.CUSTOMERS.UPDATE.replace('{customerId}', this.customer.accountId)
        },
    },
    methods: {
        ...mapActions('customer', ['fetchCustomer', 'setDefaultBillingAddressForCustomer', 'setDefaultShippingAddressForCustomer', 'updateCustomer']),
        ...mapActions('auth', ['resetPassword']),
        ...mapActions('shop', ['checkShop']),
        ...mapActions('order', ['setPresetCustomer']),
        handleConfigurationInput (data) {
            this.accountConfigurations = data.value
            this.hasConfigurationError = data.hasError
        },
        getAccountConfigurationFieldValue (configurationKey, fieldKey) {
            if (!this.customer || !this.customer.accountConfiguration || !this.customer.accountConfiguration[configurationKey]) {
                return ''
            }
            const value = this.customer.accountConfiguration[configurationKey][fieldKey]

            if (Array.isArray(value)) {
                return value.join(', ')
            }
            return value
        },
        downloadInvoice (data) {
            const url = CONFIG.API.ROUTES.INVOICES.DOWNLOAD.replace('{objectName}', data.invoiceUri)
            this.addJob(url)
            this.$api.get(url).then(({ data }) => {
                window.open(data.url, '_blank')
            }).catch(err => {
                this.$emit('error', err.response)
            }).finally(() => {
                this.finishJob(url)
            })
        },
        impersonate () {
            const url = CONFIG.API.ROUTES.CUSTOMERS.IMPERSONATE.replace('{customerId}', this.$route.params.customerId)
            this.addJob(url)
            this.impersonating = true
            this.$api.post(url, {}).then(({ data }) => {
                // @todo use vue.js instead pure js
                document.getElementById('impersonateForm.accessToken').value = data.data.accessToken
                document.getElementById('impersonateForm').submit()
            }).catch(err => {
                console.error(err)
            }).finally(() => {
                this.finishJob(url)
                this.impersonating = false
            })
        },
        addAddress () {
            this.isDialogOpen = true
            this.addressFormOptions = {
                action: CONFIG.API.ROUTES.CUSTOMERS.ADDRESS.ADD
                    .replace('{customerId}', this.$route.params.customerId)
                    .replace('{organisationId}', this.customer.organisation.organisationId),
                title: 'Add new address',
                notification: 'Address added',
            }
        },
        editAddress (data) {
            this.addressFormOptions = {
                action: CONFIG.API.ROUTES.CUSTOMERS.ADDRESS.UPDATE
                    .replace('{customerId}', this.$route.params.customerId)
                    .replace('{organisationId}', this.customer.organisation.organisationId),
                title: 'Update address',
                formData: data,
                notification: 'Address was updated',
            }
            this.isDialogOpen = true
        },
        setDefaultBillingAddress (data) {
            const url = CONFIG.API.ROUTES.CUSTOMERS.ADDRESS.SET_DEFAULT_BILLING.replace('{customerId}', this.$route.params.customerId).replace('{addressId}', data.addressId)
            this.setDefaultBillingAddressForCustomer({ url: url }).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Default billing address was set',
                })
            })
        },
        setDefaultShippingAddress (data) {
            const url = CONFIG.API.ROUTES.CUSTOMERS.ADDRESS.SET_DEFAULT_SHIPPING.replace('{customerId}', this.$route.params.customerId).replace('{addressId}', data.addressId)
            this.setDefaultShippingAddressForCustomer({ url: url }).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Default shipping address was set',
                })
            })
        },
        applyFilter (data) {
            this.$refs.table.applyFilter(data)
        },
        applyFilterToQuotes (data) {
            this.$refs.tableQuotes.applyFilter(data)
        },
        getDateOffset (offset) {
            return moment().utc().subtract(offset, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ssZ')
        },
        success () {
            this.isDialogOpen = false
            this.$refs.customerAddresses.$refs.table.refreshGrid()
        },
        resetForm () {
            Object.assign(this.addressFormOptions, {})
        },
        requestResetPassword () {
            this.isResetting = true
            this.resetPassword({ accountId: this.$route.params.customerId, username: this.customer.username }).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Password reset email was sent',
                })
                this.isResetting = false
            })
        },
        revokeCustomer () {
            const url = CONFIG.API.ROUTES.CUSTOMERS.REVOKE.replace('{customerId}', this.$route.params.customerId)
            this.isLoading = true
            this.post(url, {}, 'Revoke success').then(({ data }) => {
                this.isLoading = false
                this.updateCustomer({
                    data: {
                        dateRevoked: data.dateRevoked,
                    } })
            })
        },
        reactivateCustomer () {
            const url = CONFIG.API.ROUTES.CUSTOMERS.REACTIVATE.replace('{customerId}', this.$route.params.customerId)
            this.isLoading = true
            this.post(url, {}, 'Reactivate success').then(({ data }) => {
                this.isLoading = false
                this.updateCustomer({
                    data: {
                        dateRevoked: null,
                    } })
            })
        },
        addOrderForCustomer () {
            this.setPresetCustomer(this.customer)
            this.$router.push({
                name: 'AddOrder',
            })
        },
        listen () {
            this.$router.push({
                name: 'CustomersWrapper',
            })
        },
        saveAccountConfiguration () {
            const payload = {
                accountConfiguration: this.accountConfigurations,
            }

            this.isEditingAccountConfigurations = false
            this.submit(this.updateAction, payload).then((data) => {
                this.updateCustomer({ data: data.data })

                this.$root.$emit('notification:global', {
                    message: 'Account info was updated',
                })
            }).then(() => {
                this.fetchCustomer({
                    accountId: this.$route.params.customerId,
                })
            })
        },
    },
    created () {
        this.checkShop(this.$route.params.applicationId)
        this.fetchCustomer({
            accountId: this.$route.params.customerId,
        })

        const payload = {
            applicationId: this.selectedShop.applicationId,
        }
        this.post(CONFIG.API.ROUTES.CUSTOMERS.CONFIGURATIONS.CONFIGURATION_SCHEMAS, payload)
            .then(({ data }) => {
                this.$set(this, 'accountConfigurationSchemas', data)

                this.isLoadingConfigurations = false
            })
    },
    mounted () {
        this.$bus.$on('shopWasChangedOnUniqueView', this.listen)
    },
    destroyed () {
        this.$bus.$off('shopWasChangedOnUniqueView', this.listen)
    },
}
</script>
