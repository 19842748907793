<template>
    <mercur-card class="mb-4">
        <h2 class="mt-1 font-weight-normal">General information
            <mercur-button v-if="hasPermission('MerchantCentral/updateAccount') && customer.accountType !== 'ANONYMOUS'" class="btn btn-icon" @click.native="edit">
                <i class="fas fa-edit"></i>
            </mercur-button>
        </h2>
        <form v-if="isEditing" @submit.prevent="submitForm">
            <div class="row">
                <div class="col-6">
                    <mercur-input v-model="form.firstName" :class="getValidationClass($v, 'firstName')">
                        First name
                        <template #note>
                            <span class="form-error" v-if="!$v.form.firstName.required">First name is required</span>
                        </template>
                    </mercur-input>
                </div>
                <div class="col-6">
                    <mercur-input v-model="form.lastName" :class="getValidationClass($v, 'lastName')">
                        Last name
                        <template #note>
                            <span class="form-error" v-if="!$v.form.lastName.required">Last name is required</span>
                        </template>
                    </mercur-input>
                </div>
                <div class="col-6">
                    <mercur-input type="email" v-model="form.email" :class="getValidationClass($v, 'email')">
                        Email
                        <template #note>
                            <span class="form-error" v-if="!$v.form.email.required">Email is required</span>
                        </template>
                    </mercur-input>
                </div>
                <div class="col-6">
                    <mercur-input type="email" v-model="form.secondaryEmail">
                        Secondary email
                    </mercur-input>
                </div>
                <div class="col-6">
                    <mercur-input v-model="form.phone" :class="getValidationClass($v, 'phone')">
                        Phone
                        <template #note>
                            <span class="form-error" v-if="!$v.form.phone.required">Phone is required</span>
                        </template>
                    </mercur-input>
                </div>
                <div class="col-6">
                    <mercur-input v-model="form.phoneMobile">
                        Phone mobile
                    </mercur-input>
                </div>
                <div class="col-3">
                    <mercur-input v-model="form.accountSettings.outstandingAmount">
                        Outstanding amount
                    </mercur-input>
                </div>
                <div class="col-3">
                    <mercur-input v-model="form.accountSettings.maximum">
                        Maximum amount per order
                    </mercur-input>
                </div>
                <div class="col-6">
                    <language-selector class="mb-3" v-model="form.language" :$v="$v.form.language" />
                </div>
                <div class="col-6">
                    <country-selector class="mb-3" v-model="form.countryCode" :$v="$v.form.countryCode" reducer="countryShortCode" :is-lower-case="true"></country-selector>
                </div>
            </div>
            <div class="mb-3">
                <span class="mr-2">
                    <mercur-checkbox v-model="form.accountSettings.smsWhenUrgent" id="urgentsms">Sms when urgent</mercur-checkbox>
                </span>
                <mercur-checkbox v-model="form.accountSettings.signupForNewsletter" id="newsletters">Signup for newsletters</mercur-checkbox>
            </div>
            <template v-if="hasPermission('MerchantCentral/listCustomersGroups')">
                <merchant-data-table class="c-customer__groups-table" :options="options" :url="url" ref="table"></merchant-data-table>
            </template>
            <div>
                <mercur-button type="submit" class="btn btn-primary mr-2" :disabled="loading">Save</mercur-button>
                <mercur-button class="btn" @click.native="cancel">Cancel</mercur-button>
            </div>
        </form>
        <div class="row" v-else>
            <div class="col-6">
                <table class="table table-sm table-borderless">
                    <tbody>
                        <tr>
                            <td>Name:</td>
                            <td><strong>{{ customer.firstName }} {{ customer.lastName }}</strong></td>
                        </tr>
                        <tr>
                            <td>E-mail:</td>
                            <td><strong>{{ customer.email }}</strong></td>
                        </tr>
                        <tr>
                            <td>Account number:</td>
                            <td><strong>{{ customer.accountNumber }}</strong></td>
                        </tr>
                        <tr>
                            <td>Secondary e-mail:</td>
                            <td><strong v-if="customer.secondaryEmail">{{ customer.secondaryEmail }}</strong></td>
                        </tr>
                        <tr>
                            <td>Registration Date:</td>
                            <td><strong>{{ customer.dateCreated }}</strong></td>
                        </tr>
                        <tr>
                            <td>Shop:</td>
                            <td><strong>{{ customer.application.applicationName }}</strong></td>
                        </tr>
                        <tr>
                            <td>Phone:</td>
                            <td><strong>{{ customer.phone }}</strong></td>
                        </tr>
                        <tr>
                            <td>Phone Mobile:</td>
                            <td><strong v-if="customer.phoneMobile">{{ customer.phoneMobile }}</strong></td>
                        </tr>
                        <tr>
                            <td>Number of orders:</td>
                            <td><strong v-if="customer.profile && !Array.isArray(customer.profile)">{{ customer.profile.totalOrderCount }}</strong></td>
                        </tr>
                        <tr>
                            <td>Total amount spent:</td>
                            <td><strong v-if="customer.profile && !Array.isArray(customer.profile)"><currency :value="customer.profile.lifetimeRevenue" :type="customer.currency"></currency></strong></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-6">
                <table class="table table-sm table-borderless">
                    <tbody>
                        <tr>
                            <td>Newsletter:</td>
                            <td><strong><i class="far fa-check-circle" v-if="customer.accountSettings.signupForNewsletter"></i><i v-else class="far fa-times-circle"></i></strong></td>
                        </tr>
                        <tr>
                            <td>SMS:</td>
                            <td><strong><i class="far fa-check-circle" v-if="customer.accountSettings.smsWhenUrgent"></i><i v-else class="far fa-times-circle"></i></strong></td>
                        </tr>
                        <tr>
                            <td>Amount of placed orders:</td>
                            <td><strong v-if="customer.accountSettings.amountOrdersPlaces">{{ customer.accountSettings.amountOrdersPlaces }}</strong></td>
                        </tr>
                        <tr>
                            <td>Outstanding amount:</td>
                            <td><strong v-if="customer.accountSettings.outstandingAmount">{{ customer.accountSettings.outstandingAmount }}</strong></td>
                        </tr>
                        <tr>
                            <td>Maximum amount per order:</td>
                            <td><strong v-if="customer.accountSettings.maximum">{{ customer.accountSettings.maximum }}</strong></td>
                        </tr>
                        <tr>
                            <td>Language:</td>
                            <td><strong v-if="customer.language">{{ customer.language }}</strong></td>
                        </tr>
                        <tr>
                            <td>Country:</td>
                            <td><strong v-if="customer.countryCode">{{ customer.countryCode }}</strong></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <hr>
        <div class="row mt-3">
            <div class="col-6">
                <h2 class="mt-1 font-weight-normal">Default Billing Address</h2>
                <table v-if="customer.defaultBillingAddress" class="table table-sm table-borderless">
                    <tbody>
                        <tr><td> {{ customer.defaultBillingAddress.lastName }}</td></tr>
                        <tr v-if="customer.defaultBillingAddress.companyName"><td>{{ customer.defaultBillingAddress.companyName }}</td></tr>
                        <tr><td>{{ customer.defaultBillingAddress.address }} {{ customer.defaultBillingAddress.addressAdditional }}</td></tr>
                        <tr><td>{{ customer.defaultBillingAddress.zipCode }} {{ customer.defaultBillingAddress.city }}, {{ customer.defaultBillingAddress.country }}</td></tr>
                    </tbody>
                </table>
            </div>
            <div class="col-6">
                <h2 class="mt-1 font-weight-normal">Default Shipping Address</h2>
                <table v-if="customer.defaultShippingAddress" class="table table-sm table-borderless">
                    <tbody>
                        <tr><td>{{ customer.defaultShippingAddress.firstName }} {{ customer.defaultShippingAddress.lastName }}</td></tr>
                        <tr><td>{{ customer.defaultShippingAddress.companyName }}</td></tr>
                        <tr><td>{{ customer.defaultShippingAddress.address }} {{ customer.defaultShippingAddress.addressAdditional }}</td></tr>
                        <tr><td>{{ customer.defaultShippingAddress.zipCode }} {{ customer.defaultShippingAddress.city }}, {{ customer.defaultShippingAddress.country  }}</td></tr>
                    </tbody>
                </table>
            </div>
        </div>
    </mercur-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CONFIG from '@root/config'
import FormMixin from '@/mixins/Form'
import { setCustomerUpdatePayload } from '@/utils/payloadHelper'
import CheckboxCell from '@/components/elements/table/CheckboxCell'
import MerchantDataTable from '@/components/elements/MerchantDataTable'
import { required } from 'vuelidate/lib/validators'
import CountrySelector from '../CountrySelector'
import LanguageSelector from '../LanguageSelector'

export default {
    name: 'CustomerGeneralInformation',
    mixins: [FormMixin],
    components: { MerchantDataTable, CountrySelector, LanguageSelector },
    data () {
        return {
            isEditing: false,
            form: {},
            beforeEditingCache: null,
            url: CONFIG.API.ROUTES.CUSTOMERS.GROUPS.OVERVIEW,
            options: {
                columns: {
                    '': {
                        field: 'selected',
                        width: 35,
                        maxWidth: 35,
                        cellRendererFramework: CheckboxCell,
                        suppressMenu: true,
                        cellRendererParams: (params) => {
                            return {
                                disabled: this.loading,
                                getValue: () => {
                                    if (!this.form.accountSettings.customerGroups || !this.form.accountSettings.customerGroups.length) {
                                        return false
                                    }
                                    if (this.form.accountSettings.customerGroups.find((item) => item.groupId === params.data.groupId)) {
                                        return true
                                    }
                                    return false
                                },
                                setValue: (value) => {
                                    if (value === true) {
                                        if (!this.form.accountSettings.customerGroups) {
                                            this.$set(this.form.accountSettings, 'customerGroups', [])
                                        }
                                        this.form.accountSettings.customerGroups.push({
                                            groupId: params.data.groupId,
                                        })
                                    } else {
                                        const index = this.form.accountSettings.customerGroups.findIndex((item) => item.groupId === params.data.groupId)
                                        this.form.accountSettings.customerGroups.splice(index, 1)
                                    }
                                },
                                isDisabled: () => {
                                    return false
                                },
                            }
                        },
                    },
                    'Customer Group Name': { field: 'groupName' },
                },
                actions: {
                    type: 'dropdown',
                    items: [
                        {
                            text: 'Set as default group',
                            onClick: ({ data }) => {
                                if (!this.form.accountSettings.customerGroups) {
                                    this.$set(this.form.accountSettings, 'customerGroups', [])
                                }
                                if (!this.form.accountSettings.customerGroups.map((e) => e.groupId).includes(data.groupId)) {
                                    this.form.accountSettings.customerGroups.push({
                                        groupId: data.groupId,
                                    })
                                }
                                this.$set(this.form.accountSettings, 'defaultCustomerGroup', data.groupName)
                            },
                        },
                    ],
                },
                paginationPageSize: 10,
                cacheBlockSize: 10,
                defaultColDef: { menuTabs: [] },
            },
        }
    },
    computed: {
        ...mapState('customer', ['customer']),
        action () {
            return CONFIG.API.ROUTES.CUSTOMERS.UPDATE.replace('{customerId}', this.customer.accountId)
        },
    },
    validations: {
        form: {
            firstName: {
                required,
            },
            lastName: {
                required,
            },
            email: {
                required,
            },
            phone: {
                required,
            },
            language: {
                required,
            },
            countryCode: {
                required,
            },
        },
    },
    methods: {
        ...mapActions('customer', ['updateCustomer', 'fetchCustomer']),
        submitForm () {
            let payload = setCustomerUpdatePayload(this.form)

            if (payload.accountSettings.customerGroups) {
                payload.accountSettings.customerGroups = payload.accountSettings.customerGroups.map((e) => {
                    return {
                        groupId: e.groupId,
                    }
                })
            }

            this.submit(this.action, payload).then((data) => {
                this.updateCustomer({ data: data.data })

                this.$root.$emit('notification:global', {
                    message: 'Account info was updated',
                })
                this.isEditing = false
            }).then(() => {
                this.fetchCustomer({
                    accountId: this.$route.params.customerId,
                })
            })
        },
        edit () {
            if (this.isEditing) {
                this.cancel()
            }
            this.isEditing = true
            this.beforeEditingCache = Object.assign({}, JSON.parse(JSON.stringify(this.form)))
        },
        cancel () {
            this.isEditing = false
            Object.assign(this.form, this.beforeEditingCache)
        },
    },
    mounted () {
        this.form = this.customer
    },
}
</script>

<style lang="scss" scoped>
    .c-addresses {
        h3 {
            margin-bottom: 0;
        }
    }
</style>
